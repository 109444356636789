.btn-lanjutkan {
  color: #fff;
  background-color: #B92362;
  padding: 14px;
  border-radius: 8px;
  border: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0px 8px 10px rgb(0 0 0 / 12%);
  width: 100%;
}

.btn-lanjutkan:hover {
  opacity: 0.7;
}

.btn-lewati {
  margin-top: 5px;
  color: #010101;
  background-color: transparent;
  padding: 14px;
  border-radius: 8px;
  border: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
}

.btn-lewati:hover {
  color: grey;
  background-color: transparent;
  border-color: transparent;
}

.font-16 {
  font-size: 16px !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.error {
  border: 1px solid red !important;
  color: red !important;
}

@media only screen and (max-width: 320px) {
  .powered-image {
    width: 100px;
    height: 50px;
    margin-top: 20px;
  }

  .logo {
    width: 100px !important;
    height: 100px !important;
  }
}