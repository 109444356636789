.courier-drawer::-webkit-scrollbar,
.bank-drawer::-webkit-scrollbar {
  width: 10px;
}

.courier-drawer::-webkit-scrollbar-track,
.bank-drawer::-webkit-scrollbar-track {
  background: #cccccc;
}

.courier-drawer::-webkit-scrollbar-thumb,
.bank-drawer::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
