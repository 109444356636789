.payment-container {
    overflow: hidden;
}

@media only screen and (max-width: 320px) {
    .illustration {
        width: 100px;
        height: 100px;
    }

    .info-box {
        margin: 50px 0 20px 0;
    }

    .title {
        font-size: 14px;
    }

}