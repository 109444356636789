
::-moz-selection {
  color: white;
  background: #FCB017;
}

::selection {
  color: white;
  background: #FCB017;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fefefe;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f0f0f0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast-theme--light {
  background: white;
  color: #757575;
  font-size: 12px;
  border-radius: 24px;
}

.Toastify__progress-bar {
  height: 3px;
}