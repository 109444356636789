.font-16 {
  font-size: 16px !important;
}

.w-56 {
  width: 56px !important;
}

.w-35 {
  width: 35px !important;
}

.w-95 {
  width: 95% !important;
}

.card-menu {
  width: 80%;
  background: #FFFFFF;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.card-menu:hover {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.282);
}

.card-btn {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.card-btn:after {
  background-color: #fff;
  opacity: 0.9;
}

.card-btn:active {
  background-color: #fff;
  opacity: 0.9;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.mt-15-p {
  margin-top: 15%;
}

.logo {
  margin-top: 15px;
}

@media only screen and (max-width: 320px) {

  .logo {
    width: 90px !important;
    height: 90px !important;
  }

  .service-text {
    margin: 10px 0 !important;
  }

  .intro-text-1 {
    font-size: 14px !important;
    margin: 10px 0 0 0 !important;
  }

  .intro-text-2 {
    font-size: 14px !important;
    margin: -10px 0 10px 0 !important;
  }

  .powered-image {
    width: 100px;
    height: 50px;
  }
}