.font-18 {
  font-size: 18px !important;
}

.mt-30-p {
  margin-top: 30% !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.store-section-title,
.store-section-show-all-btn {
  text-transform: none !important;
  font-family: D-DIN Exp, sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: bold !important;
  letter-spacing: 0.02rem;
}

.scroll-box {
  width: 100%;
  overflow-x: auto;
}

.scroll-box::-webkit-scrollbar {
  display: none;
}

.store-item-container {
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
  cursor: pointer;
}

.store-item-container-v2 {
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  width: 100%;
  border-radius: 5px;
}

.store-item-container:hover,
.store-item-container-v2:hover {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.store-item-logo,
.product-image {
  width: 134px;
  height: 116px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: hidden;
}

.store-item-logo-v2 {
  width: 75px;
  height: 75px;
}

.store-item-logo-v2__img {
  width: 75px;
  height: 75px;
  object-fit: contain;
}

.product-image {
  position: relative;
}

.store-item-logo__img,
.product-image__img {
  width: 134px;
  height: 116px;
  object-fit: contain;
}

.store-item-name,
.store-item-location {
  font-family: D-DIN Exp, sans-serif !important;
  font-weight: bold;
}

.store-item-name {
  font-size: 0.9rem;
  color: #000000;
}

.store-item-location {
  font-size: 0.7rem;
  color: #cccccc;
}

.product-item-container {
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
  cursor: pointer;
}

.product-item-container:hover {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.product-item-container-v2 {
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.product-item-container-v2:hover {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.product-image-v2 {
  width: 134px;
  height: 80px;
  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.product-image-v2__img {
  width: 134px;
  height: 80px;
  object-fit: contain;
}

.product-discount-tag {
  background: #ff2622;
  height: 35px;
  width: 35px;
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 8px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product-discount-tag__discount,
.product-discount-tag__label {
  font-family: D-DIN Exp, sans-serif !important;
}

.product-discount-tag__label {
  font-size: 7px;
  font-weight: 800;
  line-height: 1.2;
}

.product-discount-tag__discount {
  font-size: 12px;
  font-weight: 800;
  line-height: 1.2;
}

.product-name {
  font-family: D-DIN Exp, sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  letter-spacing: 0.2px;
  padding: 0 0px;
  position: static;
}

.product-store-name {
  font-family: D-DIN Exp, sans-serif;
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  color: #999999;
  letter-spacing: 1px;
  padding: 0px 0px 0px 0px;
}

.product-price,
.product-price-label {
  font-family: D-DIN Exp, sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-align: right;
  padding: 0;
}

.product-price-before-discount {
  font-family: D-DIN Exp, sans-serif;
  font-size: 11px;
  line-height: 14px;
  font-weight: 300;
  text-align: right;
  padding: 0;
  text-decoration: line-through;
}

.category-container {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 10px;
  margin: auto;
}

.category-logo {
  background: #ffffff;
  width: 75px;
  height: 75px;
  border-radius: 5px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.category-logo__img {
  width: 75px;
  height: 75px;
  object-fit: cover;
}
