.filter-container {
  max-height: 75vh;
  overflow-y: auto;
  margin: 3vh 0 1vh 0;
}

.filter-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.filter-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.filter-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.filter-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
